import React, { FC } from "react"
import Layout from "../../components/Layout"
import { StaticImage } from "gatsby-plugin-image"

const stutz: FC = () => {
  const titleImage = "_DSC2275.jpg"
  const image1 = "_DSC2279.jpg"
  const image2 = "_DSC2286.jpg"
  const image3 = "_DSC2296.jpg"
  const image4 = "_DSC2350.jpg"
  const image5 = "_DSC2352.jpg"
  const image6 = "_DSC2359.jpg"
  const image7 = "_DSC2390.jpg"
  const image8 = "_DSC2391.jpg"
  const image9 = "_DSC2402.jpg"

  return (
    <Layout>
      <div className="single-project">
        <div className="single-project-picture-landscape">
          <StaticImage
            src={"../../assets/images/Stutz/" + titleImage}
            alt="Ghidoni"
          />
        </div>
        <h3 className="single-project-picture-landscape">
          Stutz 2020
          <h5 className="single-project-text">
            Ristrutturazione completa di un vecchio rustico nel nucleo di
            Ropiana a Mergoscia. Sono stati demoliti tutti gli interni per poter
            disporre meglio degli spazi, originariamente molto ridotti. A
            livello concettuale si è cercato di mantenere le caratteristiche
            originali dell’edificio lasciando aperti gli ampi ballatoi esterni
            collegati tra i tre piani da una scala in larice. Per mettere in
            risalto l’identità originale tutte le travi sono state levigate e
            lasciate a vista. Anche la carpenteria originale a capriata è stata
            mantenuta, costruendoci sopra il nuovo tetto coibentato. I
            proprietari -che sono falegnami- si sono occupati di isolare gli
            interni con della schiuma di calce tipo Haga, di posare le nuove
            finestre, la cucina, i pavimenti, le pareti divisorie interne e
            della sistemazione esterna.
          </h5>
        </h3>
        <div className="single-project-picture-landscape">
          <StaticImage
            src={"../../assets/images/Stutz/" + image1}
            alt="Alambicco"
          />
        </div>
        <div className="single-project-picture-landscape">
          <StaticImage
            src={"../../assets/images/Stutz/" + image2}
            alt="Alambicco"
          />
        </div>
        <div className="single-project-picture-landscape">
          <StaticImage
            src={"../../assets/images/Stutz/" + image3}
            alt="Alambicco"
          />
        </div>
        <div>
          <StaticImage
            src={"../../assets/images/Stutz/" + image4}
            alt="Alambicco"
          />
        </div>
        <div>
          <StaticImage
            src={"../../assets/images/Stutz/" + image5}
            alt="Alambicco"
          />
        </div>
        <div className="single-project-picture-landscape">
          <StaticImage
            src={"../../assets/images/Stutz/" + image7}
            alt="Alambicco"
          />
        </div>
        <div>
          <StaticImage
            src={"../../assets/images/Stutz/" + image6}
            alt="Alambicco"
          />
        </div>
        <div>
          <StaticImage
            src={"../../assets/images/Stutz/" + image9}
            alt="Alambicco"
          />
        </div>
        <div className="single-project-picture-landscape">
          <StaticImage
            src={"../../assets/images/Stutz/" + image8}
            alt="Alambicco"
          />
        </div>
      </div>
    </Layout>
  )
}

export default stutz
